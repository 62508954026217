<template>
  <div class="main-view">
    <div class="workWrap">
      <el-row :gutter="40" style="margin:16px 24px">
        <el-col :span="6">
          <el-cascader
            :popper-append-to-body="false"
            popper-class="areaCascaderWrap"
            v-model="area_str"
            :options="areaArr"
            :props="{ checkStrictly: true }"
            placeholder="全部地区"
            clearable
            @change="getBaseInfo">
          </el-cascader>
        </el-col>
        <el-col :span="6">
          <el-date-picker
            v-model="selDate"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            @change="getBaseInfo">
          </el-date-picker>
        </el-col>
      </el-row>

      <div class="echartWrap">
        <el-row class="top">
          <el-col :span="10" class="topLeft">
            <el-row style="width:100%;display:flex;align-items: center;">
              <el-col :span="12" style="display:flex;align-items: center;">
                <div class="chart1">
                  <Echart :options="options1" height="100%" width="100%"></Echart>
                </div>
                <div style="margin:0 20px 0 10px;">
                  <div style="margin-bottom:10px;">发布需求</div>
                  <div><span class="num1" style="color:#007DF2">{{dataInfo.job.total}}</span>条</div>
                </div>
              </el-col>
              <el-col :span="12">
                <div style="padding:0 30px;">
                  <div class="status status1">
                    <span>招工中</span>
                    <span class="num2">{{dataInfo.job.num1}}</span>
                    <span>({{dataInfo.job.rate1}}%)</span>
                  </div>
                  <div class="status status2">
                    <span>已招满</span>
                    <span class="num2">{{dataInfo.job.num2}}</span>
                    <span>({{dataInfo.job.rate2}}%)</span>
                  </div>
                  <div class="status status3">
                    <span>已结束</span>
                    <span class="num2">{{dataInfo.job.num3}}</span>
                    <span>({{dataInfo.job.rate3}}%)</span>
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row style="width:100%;display:flex;align-items: center;" >
              <el-col :span="12" style="display:flex;align-items: center;">
                <div class="chart1">
                  <Echart :options="options2" height="100%" width="100%"></Echart>
                </div>
                <div style="margin:0 20px 0 10px;">
                  <div style="margin-bottom:10px;">用工需求</div>
                  <div><span class="num1" style="color:#F7A52F">{{dataInfo.worker.total}}</span>人</div>
                </div>
              </el-col>
              <el-col :span="12">
                <div style="padding:0 30px;">
                  <div class="status status1">
                    <span>已用工</span>
                    <span class="num2">{{dataInfo.worker.num1}}</span>
                    <span>({{dataInfo.worker.rate1}}%)</span>
                  </div>
                  <div class="status status2">
                    <span>招工中</span>
                    <span class="num2">{{dataInfo.worker.num2}}</span>
                    <span>({{dataInfo.worker.rate2}}%)</span>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="14" class="topRight">
            <div class="topRight-title">
              <div>工单统计</div>
            </div>
            <div class="chart2">
              <Echart :options="options3" height="100%" width="100%"></Echart>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="14">
            <div class="title2">热门用工需求（前10）</div>
            <el-row :gutter="30" style="padding:20px;">
              <template v-if="dataInfo.kind_hot.length > 0">
                <el-col style="margin-top: 30px;" :span="12" v-for="item in dataInfo.kind_hot.slice(0,10)" :key="item">
                  <div style="margin-bottom:10px;display: flex;justify-content: space-between;"  >
                    <span>{{item.kinds_str}}</span>
                    <span>{{item.num}}人</span>
                  </div>
                  <!-- <Echart :options="options4" height="30px" width="100%"></Echart> -->
                  <el-progress :text-inside="true" :stroke-width="20" :percentage="item.num" color="#2DABFF"></el-progress>
                </el-col>
              </template>
            </el-row>
          </el-col>
          <el-col :span="10" style="border-left:solid 1px #eee;">
            <Echart :options="options5" height="449px" width="100%"></Echart>
          </el-col>
        </el-row>

        <el-row style="border-top:solid 1px #eee;border-bottom:solid 1px #eee;margin-bottom:30px;">
          <el-col :span="12" >
            <Echart :options="options6" height="449px" width="100%"></Echart>
          </el-col>
          <el-col :span="12" style="border-left:solid 1px #eee;">
            <Echart :options="options7" height="449px" width="100%"></Echart>
          </el-col>
        </el-row>

      </div>
    </div>
  </div>
</template>

<script>
import Echart from "@/components/echart/index.vue";
import * as echarts from 'echarts'
// var mockData = [
//       {total: "550", detail: "待开工"},
//       {total: "106", detail: "已完成"},
//       {total: "275", detail: "待完成"},
//       {total: "222", detail: "已取消"},
//     ];
//接口总数和标题文字
let value = 0;
export default {
  name: 'Home',
  components:{
    Echart,
  },
  data() {
    return {
      params:{
        start_time: '',
        end_time: '',
        province: '',
        city: '',
        area: '',
      },
      area_str: [],
      selDate: [],
      dataInfo: {
        job: {
          num1: 0,
          num2: 0,
          num3: 0,
          rate1: 0,
          rate2: 0,
          rate3: 0,
          total: 0,
        }, // 发布需求
        worker: {
          num1: 0,
          num2: 0,
          rate1: 0,
          rate2: 0,
          total: 0,
        }, // 用工需求
        order_line: {}, // 工单统计
        kind_hot: {}, // 热门用工需求（前10）
        order: {}, // 工单总数
        kind: {}, // 需求类型
        job_line: {}, // 招工统计
      },
      selectData: '',
      pickerOptions: {
        // onPick：选中日期后会执行的回调。写成箭头函数否则this.selectData会报错
        // 点击时，选择的是初始时间，也就是minDate
        onPick: ({ maxDate, minDate }) => {
          this.selectData = minDate.getTime();
          if (maxDate) {
            // 第二次点击日期选择器，选好初始时间和结束时间后，解除禁用限制
            this.selectData = ''
          }
        },
        // 设置禁用状态，参数为当前日期，要求返回 Boolean。同理写成箭头函数
        disabledDate: (time) => {
          // 是否限制的判断条件，if里写点击了初始时间后的判断条件
          // 即第二个选择的时间前后不能超过初始时间的一个月
          if (this.selectData) {
            return time.getTime() > new Date() || time.getTime() > this.selectData + 30 * 24 * 3600 * 1000 || time.getTime() < this.selectData - 30 * 24 * 3600 * 1000;
            // time.getTime() > new Date() 为所选时间不能超过今日
            // 30 * 24 * 3600 * 1000则为一个月的毫秒数，根据自己的需求修改限制的时间范围
          } else {
            return time.getTime() > new Date();
            // 如果不需要限制所选时间不能超过今日，则直接return false即可
          }
        },
      },
      dateStatus: 0,
      areaArr: [],
      
      options1: {
        title: {
          x: 'center',
          y: 'center',
          textStyle: {
            fontWeight: 'normal',
            color: '#1890FF',
            fontSize: '26'
          }
        },
        graphic: {
          elements: [
            {
              type: "image",
              style: {
              image: "https://qn.cdn.jszhr.com/saas/20230714/84ee6eaacf8193790a7046839b7ba7db.png",
              width: 60,
              height: 60
              },
              left: "center",
              top: "30%"
            }
          ]
        },
        tooltip: {
          formatter: function(params) {
            return params.name + '：' + params.percent + ' %'
          }
        },
        legend: {
          show: false,
          itemGap: 12,
          data: ['占比', '剩余']
        },
        series: [{
          name: 'circle',
          type: 'pie',
          clockWise: true,
          radius: ['50%', '66%'],
          itemStyle: {
            normal: {
              label: {
                show: false
              },
              labelLine: {
                show: false
              }
            }
          },
          hoverAnimation: false,
          data: [{
            value: 65,
            name: '占比',
            itemStyle: {
              normal: {
                color: { // 颜色渐变
                  colorStops: [{
                    offset: 0,
                    color: '#218AE6' // 0% 处的颜色
                  }, {
                    offset: 1,
                    color: '#69B8FF' // 100% 处的颜色1
                  }]
                },
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                }
              }
            }
          }, {
            name: '剩余',
            value: 100 - 65,
            itemStyle: {
              normal: {
                color: '#DFEFFE'
              }
            }
          }]
        }]
      },
      options2: {
        title: {
          x: 'center',
          y: 'center',
          textStyle: {
            fontWeight: 'normal',
            color: '#1890FF',
            fontSize: '26'
          }
        },
        graphic: {
          elements: [
            {
              type: "image",
              style: {
              image: "https://qn.cdn.jszhr.com/saas/20230714/ab8d34a088726404a5fe35fd5a89380a.png",
              width: 60,
              height: 60
              },
              left: "center",
              top: "30%"
            }
          ]
        },
        tooltip: {
          formatter: function(params) {
            return params.name + '：' + params.percent + ' %'
          }
        },
        legend: {
          show: false,
          itemGap: 12,
          data: ['占比', '剩余']
        },
        series: [{
          name: 'circle',
          type: 'pie',
          clockWise: true,
          radius: ['50%', '66%'],
          itemStyle: {
            normal: {
              label: {
                show: false
              },
              labelLine: {
                show: false
              }
            }
          },
          hoverAnimation: false,
          data: [{
            value: 65,
            name: '占比',
            itemStyle: {
              normal: {
                color: { // 颜色渐变
                  colorStops: [{
                    offset: 0,
                    color: '#F8AC3F' // 0% 处的颜色
                  }, {
                    offset: 1,
                    color: '#FFF5E3' // 100% 处的颜色1
                  }]
                },
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                }
              }
            }
          }, {
            name: '剩余',
            value: 100 - 65,
            itemStyle: {
              normal: {
                color: '#FFF5E3'
              }
            }
          }]
        }]
      },
      options3: {
        tooltip: {
          trigger: 'axis',
          backgroundColor: "#4C4C4C",
          textStyle: {
              color: "#fafafa"
          },
        },
        grid:{
          left: '5%',
          bottom:'50px'
        },
        legend: {
          show: true,
          itemGap: 12,
          top:'10px',
          data: ['工单数', '交易金额']
        },
        xAxis: [
          {
            type: 'category',
            data: [],
            // data: ['06-01', '06-02', '06-03', '06-04', '06-05', '06-06', '06-07'],
            axisPointer: {
              type: 'line',  // 鼠标移上去显示（虚线：line，阴影：shadow）
            }
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: '工单数量',
            min: 0,
            max: 100,
            interval: 10,
            axisLabel: {
              formatter: '{value}'
            },
             splitLine: {
               show: true,
               lineStyle: {
               color: '#E2E8EC',
               type: 'dashed'
               }
            },
          },
          {
            type: 'value',
            name: '交易金额',
            min: 0,
            max: 1000,
            interval: 100,
            axisLabel: {
              formatter: '{value} 元'
            },
             splitLine: {
               show: true,
               lineStyle: {
               color: '#E2E8EC',
               type: 'dashed'
               }
            },
          },
        ],
        series: [
          {
            name: '工单数',
            type: 'bar',
            barWidth: '20%',
            tooltip: {
              valueFormatter: function (value) {
                return value ;
              }
            },
            data: [],
            // data: [29, 59, 91, 126, 28, 87, 16],
            itemStyle: {
              // 普通样式。
              normal: {
                // 柱子的颜色。
                color: '#FFD48F'
              }
            }
          },
          {
            name: '交易金额',
            type: 'line',
            smooth:true,
            symbolSize: 15,
            lineStyle: {
              width: 5,
            },
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 元';
              }
            },
            data: [],
            // data: [24.0, 129.2, 96.3, 43.5, 61.3, 100.2, 76.3],
            itemStyle: {
              // 普通样式。
              normal: {
                // 点的颜色。
                color: '#007DF2'
              }
            }
          }
        ]
      },
      options4: {
        grid: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
        xAxis: {
          show: false,
          type: 'value',
          boundaryGap: [0, 0],
        },
        yAxis: [
          {
            type: 'category',
            data: [''],
            axisLine: { show: false },
            axisTick: [
              {
                show: false,
              },
            ],
          },
        ],
        series: [
          {
            name: '金额',
            type: 'bar',
            zlevel: 1,
            itemStyle: {
              normal: {
                barBorderRadius: 30,
                color: new echarts.graphic.LinearGradient(1, 0, 0, 1, [
                  {
                    offset: 1,
                    color: '#2DABFF',
                  },
                  {
                    offset: 0,
                    color: '#9BC9F9',
                  },
                ]),
              },
            },
            barWidth: 20,
            data: [800],
          },
          {
            name: '背景',
            type: 'bar',
            barWidth: 20,
            barGap: '-100%',
            data: [1000],
            itemStyle: {
              normal: {
                color: '#E9EEF4',
                borderWidth: 1,
                borderColor: '#E9EEF4',
                barBorderRadius: 30,
              },
            },
          },
        ],
      },
      options5: {
        color: ['#FFA84C','#50C1F6', '#8065F6', '#FF7A8C'],
        title: {
          text: '{a|' + value + '}\n{c|' + '工单总数' + '}',
          x: '49%',
          y: '30%',
          textAlign:'center',
          textStyle: {
            rich: {
              a: {
                fontSize: 30,
                color: '#000',
                fontWeight:'600',
              },
              c: {
                fontSize:16,
                color: '#555',
                padding: [5, 0]
              }
            }
          }
        }, 
        grid: {
          top: 0,
          left: '200px',
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['42%', '30%'],
            center: ['50%', '36%'],
            clockwise: false,
            avoidLabelOverlap: false,
            hoverOffset: 10,
            legendHoverLink: true,
            label: {
              normal: {
                  show: false,
              },
            },
            labelLine: {
              normal: {
                  show: false
              }
            },
            itemStyle: {
              normal: {
                borderColor: '#ffffff',
              }
            },
            data: [
             
            ]
          },
          {
            type: 'pie',
            radius: [0, '25%'],
            hoverAnimation: false,
            clockWise: false,
            center: ['50%', '36%'],
            tooltip: {
              formatter:  '工单总数' +'：' + value,
              textStyle: {
                  color: '#000'
              },
              backgroundColor: '#fff',
              extraCssText: 'box-shadow: 0px 0px 10px 0px rgba(19,83,88,0.2);'
            },
            /* tooltip: { //不现实总数的提示
                  show: true
              },*/
            itemStyle: {
              normal: {
                shadowBlur: 6,
                shadowColor: '#444',
                color: new echarts.graphic.RadialGradient(0.5, 0.5, 34, [{
                  offset: 0,
                  color: '#FFF',
                }, {
                  offset: 1,
                  color: '#060f20'
                }])
              }
            },
            label: {
              normal: {
                show: false,
              },
            },
            data: [100]
          },
        ]
      },
      options6: {
        title: {
          text: '需求类型',
          x: '50%',
          y: '47%',
          textAlign:'center',
        }, 
        legend: { show: false },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '60%'],
            avoidLabelOverlap: true,
            itemStyle: { borderColor: '#fff', borderWidth: 2 },
            color: [
                '#52A8FF',
                '#00B389',
                '#E27272',
                '#FFC53D',
                '#006EFE',
                '#F5855F',
                '#C099FC',
                '#FFA940',
                '#29EFC4',
                '#F8AEA4',
            ],
            label: {
              // alignTo: 'labelLine', // ! 文字对齐方式
              formatter: function (e) {
                let {
                    data: { value, name, percent },
                } = e;
                return `{x|}{a|${name}}\n{b|${percent}%,}{c|${value}个}`;
              },
              minMargin: 5,
              lineHeight: 15,
              rich: {
                x: { width: 10, height: 10, backgroundColor: 'inherit', borderRadius: 5 },
                a: { fontSize: 14, color: 'inherit', padding: [0, 20, 0, 8] },
                b: { fontSize: 12, align: 'left', color: '#666666', padding: [8, 0, 0, 18] },
                c: { fontSize: 12, align: 'left', color: '#666666', padding: [8, 0, 0, 8] },
              },
            },
            data: [
              { value: 28, name: '家具安装', percent: '27.46%' },
              { value: 18, name: '餐饮帮厨', percent: '30.51%' },
              { value: 11, name: '搬家拉货', percent: '11.69%' },
              { value: 31, name: '打墙钻孔', percent: '25.08%' },
              { value: 11, name: '泥工瓦匠', percent: '21.69%' },
              { value: 21, name: '家电维修', percent: '23.39%' },
              { value: 16, name: '其他服务', percent: '18.17%' },
            ],
          },
        ],
      },
      options7: {
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#2e364d',
          textStyle: {
            color: "#fff"
          },
          axisPointer: {
            type: 'line',  // 鼠标移上去显示（cross）
            crossStyle: {
              color: '#999'
            }
          }
        },
        grid:{
          top:'100px'
        },
        legend: {
          data: ['报名次数','发布需求','在招需求'],
          top: '20px'
        },
        xAxis: [
          {
            type: 'category',
            data: [],
            // data: ['06-01', '06-02', '06-03', '06-04', '06-05', '06-06', '06-07','06-08','06-09'],
            axisPointer: {
              type: 'line',  // 鼠标移上去显示（虚线：line，阴影：shadow）
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '发布需求',
            min: 0,
            max: 100,
            interval: 10,
            axisLabel: {
              formatter: '{value}'
            }
          },
          {
            type: 'value',
            show:false,
            name: '在招需求',
            min: 0,
            max: 100,
            interval: 10,
            axisLabel: {
              formatter: '{value} %'
            }
          },
          
        ],
        series: [
          {
            name: '报名次数',
            type: 'line',
            smooth:true,
            symbolSize: 15,
            lineStyle: {
              width: 5,
            },
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value ;
              }
            },
            data: [],
            // data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 2.3, 6.5, 3.8],
            itemStyle: {
              // 普通样式。
              normal: {
                // 点的颜色。
                color: '#FFDC73'
              }
            }
          },
          {
            name: '发布需求',
            type: 'bar',
            barWidth: '20%',
            tooltip: {
              valueFormatter: function (value) {
                return value;
              }
            },
            data: [],
            // data: [29, 59, 91, 126, 28, 87, 16, 82, 57],
            itemStyle: {
              // 普通样式。
              normal: {
                // 柱子的颜色。
                color: '#DBEFFF'
              },
              // 鼠标移上去后柱子的颜色
              emphasis: {
                 color: '#70C1FF'
              }
            }
          },
          {
            name: '在招需求',
            type: 'bar',
            barWidth: '20%',
            tooltip: {
              valueFormatter: function (value) {
                return value;
              }
            },
            data: [],
            // data: [29, 59, 91, 126, 28, 87, 16, 82, 57],
            itemStyle: {
              // 普通样式。
              normal: {
                // 柱子的颜色。
                color: '#D6FBE8'
              },
              // 鼠标移上去后柱子的颜色
              emphasis: {
                color: '#68F3A3'
              }
            }
          },
          
        ]
      }

    }
  },
  created() {
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
    this.selDate[0] = this.$moment(start).format("YYYY-MM-DD");
    this.selDate[1] = this.$moment(end).format("YYYY-MM-DD");
  },
  mounted() {
    this.getArea();
    this.getBaseInfo();
  },
  methods: {
    // 获取省市区
    getArea() {
      this.$http.post('/common/district/all',{}).then(res => {
        if(res.code == 1) {
          this.areaArr = res.data.list;
        }
      })
    },
    // 获取数据
    async getBaseInfo() {
      let _area_arr = this.area_str;
      let _params = {
        start_time: this.selDate.length > 0 ? this.selDate[0] : '',
        end_time: this.selDate.length > 0 ? this.selDate[1] : '',
        province: !!_area_arr ? _area_arr[0] : '',
        city: !!_area_arr ? _area_arr[1] : '',
        area: !!_area_arr ? _area_arr[2] : '',
      }
      await this.$http.get('/admin/flex_index/stat',{params:_params}).then(res => {
        if(res.code === 1) {
          this.dataInfo = res.data;
          this.options3.xAxis[0].data = res.data.order_line.time;
          this.options3.series[0].data = res.data.order_line.num;
          this.options3.series[1].data = res.data.order_line.amount_line;

          this.options6.series[0].data = res.data.kind.map(item => {
            return {
              value: item.num,
              name: item.parent_kinds_str,
              percent: item.rate
            }
          })

          this.options7.xAxis[0].data = res.data.job_line.time;
          this.options7.series[0].data = res.data.job_line.num1;
          this.options7.series[1].data = res.data.job_line.num2;
          this.options7.series[2].data = res.data.job_line.num3;

          this.mockData = [
            {total: res.data.order.num1, detail: "待开工"},
            {total: res.data.order.num2, detail: "已完成"},
            {total: res.data.order.num3, detail: "待完成"},
            {total: res.data.order.num4, detail: "已取消"},
          ];
          
          this.options5.series[0].data = [ { 
              value: this.mockData[0].total,
              name: this.mockData[0].detail,
            },
            { 
              value: this.mockData[1].total,
              name: this.mockData[1].detail,
            },
            { 
              value: this.mockData[2].total,
              name: this.mockData[2].detail,
            },
            { 
              value: this.mockData[3].total,
              name: this.mockData[3].detail,
            }];

          this.options5.title.text= '{a|' + res.data.order.total + '}\n{c|' + '工单总数' + '}',
          this.options5.series[1].tooltip.formatter= '工单总数' +'：' + res.data.order.total,
          this.options5.legend = {
            icon: "circle",
            left: 'center', // 修改为居中
            bottom: '20px',
            width:400,
            height:30,
            itemWidth: 30,
            itemGap: 20,
            data: this.mockData.map(item => item.detail), 
            formatter: params => { 
                const dataAll = this.mockData.map(item => Number(item.total))
                const total = dataAll.reduce((x, y) => parseInt(x, 10) + parseInt(y, 10))
                const value = Number(this.mockData.filter(item => item.detail === params)[0].total)
                const name = params
                if (total === 0) {
                  return `\n{a|${name}}：{c|${value}}{d|   }{b|(0%)}`
                }
                return `\n{a|${name}}：{c|${value}}{d|   }{b|(${((value * 100) / total).toFixed(2)}%)}`
            },
            textStyle: {
              rich: {
                a: {
                    color: '#847777e0',
                    fontSize: 14,
                    fontWeight: 'bold',
                    align: 'left',
                    padding: [5, 0]
                },
                b: {
                    color: '#847777e0',
                    fontSize: 14,
                    fontWeight: 'bold',
                },
                c: {
                    color: '#18355C',
                    fontSize: 20,
                    fontWeight: 'bold',
                }
              }
            }, 
        }
        
        } else {
          this.$message.error(res.msg);
        }
      })
    },
   
    
  }
}
</script>

<style scoped lang="scss">
.workWrap {
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.echartWrap {
  overflow-y: scroll;
  flex: 1;
}
.top {
  height: 327px;
  border: solid 1px #eee;
}
.topLeft {
  padding: 10px 0;
  border-right: solid 1px #eee;
}
.num1 {
  font-size:30px;
  font-weight:bold;
}
.num2 {
  margin-left: 10px;
  font-size: 20px;
  font-weight: bold;
}
.status {
  height:40px;
  line-height:40px;
  border-radius: 6px;
  padding: 0 10px;
  margin:10px 0;
}
.status1 {
  background:#E8FFEE;
  color:#009427;
}
.status2 {
  background:#E8F5FF;
  color:#2385D1;
}
.status3{
  background:#FFE8EC;
  color:#E13E5A;
}
.title2 {
  margin-left: 20px;
}
.title2::before {
  content: "";
  display: inline-block;
  width: 6px;
  height: 20px;
  background: #2385D1;
  position: relative;
  top: 5px;
  left: -5px;
  border-radius: 5px;
}
.topRight-title {
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #eee;
  border-right: solid 1px #eee;
}
.chart1 {
  width: 144px;
  height: 144px;
  
}
.chart2 {
  width: 100%;
  height: 300px;
}

</style>
<style >
.el-progress-bar__innerText {
  display: none;
}
</style>